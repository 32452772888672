const path = require('path');
const { mergeSassVariables } = require('@vuetify/cli-plugin-utils');
// TODO: arreglar problema con la app principal, parece que se generan demasiados listeners para un evento. El warning:
// (node:13344) MaxListenersExceededWarning: Possible EventEmitter memory leak detected. 11 upgrade listeners added to [Server]. Use emitter.setMaxListeners() to increase limit
// (Use `node --trace-warnings ...` to show where the warning was created)
require('events').EventEmitter.defaultMaxListeners = 50;

var services = {
  //'{serviceName}': 'http://localhost:{port}/',
  //'{serviceName}': 'https://hermes-dev.geminys.com/',
  'clientmng': 'http://localhost:8090/',
  'documentmng': 'http://localhost:8091/',
  'support': 'http://localhost:8092/',
  'usermng': 'http://localhost:8093/',
  'search': 'http://localhost:8094/',
  'editor': 'http://localhost:8095/',
  'transformation': 'http://localhost:8096/',
  'log': 'http://localhost:8097/',
  'notification': 'http://localhost:8098/',
  'composer': 'http://localhost:8099/',
}

module.exports = {
  // devServer ONLY NEEDED WHEN RUNNING IN LOCAL, COMMENT IT BEFORE COMITTING CHANGES
  /*devServer: {
    hot: true,
    proxy: {
      '^/clientmng-rs/': {
        target: services.clientmng,
        changeOrigin: true,
        secure: false,
        // logLevel: 'debug' 
      },
      '^/documentmng-rs/': {
        target: services.documentmng,
        changeOrigin: true,
        secure: false,
        // logLevel: 'debug' 
      },
      '^/support-rs/': {
        target: services.support,
        changeOrigin: true,
        secure: false,
        // logLevel: 'debug' 
      },
      '^/usermng-rs/': {
        target: services.usermng,
        changeOrigin: true,
        secure: false,
        // logLevel: 'debug' 
      },
      '^/search-rs/': {
        target: services.search,
        changeOrigin: true,
        secure: false,
        // logLevel: 'debug' 
      },
      '^/editor-rs/': {
        target: services.editor,
        changeOrigin: true,
        secure: false,
        // logLevel: 'debug' 
      },
      '^/transformation-rs/': {
        target: services.transformation,
        changeOrigin: true,
        secure: false,
        // logLevel: 'debug' 
      },
      '^/notification-rs/': {
        target: services.notification,
        changeOrigin: true,
        secure: false,
        // logLevel: 'debug' 
      },
      '^/log-rs/': {
        target: services.log,
        changeOrigin: true,
        secure: false,
        // logLevel: 'debug' 
      },
      '^/composer-rs/': {
        target: services.composer,
        changeOrigin: true,
        secure: false,
        // logLevel: 'debug' 
      },
    },
    client: {
      overlay: {
        errors: false,
        warnings: false,
        runtimeErrors: false,
      },
    },
  },*/
  publicPath: '/front/',
  lintOnSave: false,
  transpileDependencies: ['vuetify'],
  configureWebpack: {
    resolve: {
      fallback: {
        "fs": false,
        "os": false,
        "path": require.resolve("path-browserify"),
        "util": false,
        "url": false,
        "https": false,
        "http": false,
        "zlib": false,
        "canvas": false,
      },
      alias: {
        '@themeConfig': path.resolve(__dirname, 'themeConfig.js'),
        '@core': path.resolve(__dirname, 'src/@core'),
        '@axios': path.resolve(__dirname, 'src/plugins/axios.js'),
        '@user-variables': path.resolve(__dirname, 'src/styles/variables.scss'),
        apexcharts: path.resolve(__dirname, 'node_modules/apexcharts-clevision'),
      },
    },
  },
  chainWebpack: config => {
    const modules = ['vue-modules', 'vue', 'normal-modules', 'normal']
    modules.forEach(match => {
      config.module
        .rule('sass')
        .oneOf(match)
        .use('sass-loader')
        .tap(opt => mergeSassVariables(opt, "'@/styles/variables.scss'"))
      config.module
        .rule('scss')
        .oneOf(match)
        .use('sass-loader')
        .tap(opt => mergeSassVariables(opt, "'@/styles/variables.scss';"))
    })
    config.module
      .rule('html')
      .test(/\.html$/)
      .use('html-loader')
      .loader('html-loader')
  },
};
